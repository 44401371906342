import { action, observable } from 'mobx';
import { create, persist } from 'mobx-persist';

export interface TypeLocationStore {
  city: any;
  trackedLocation: any;
}

class LocationStore implements TypeLocationStore {
  @observable
  @persist('object')
  city = 'Uberlândia/MG';

  @observable
  @persist('object')
  trackedLocation = '';

  @action
  getLocation = (params: any) => {
    this.city = params;
    return params;
  };

  @action
  setGeolocation = (params: any) => {
    this.trackedLocation = params;
    return params;
  };
}

export const locationStore = new LocationStore();

if (typeof window !== 'undefined') {
  const hydrate = create();
  hydrate('locale', locationStore);
}
