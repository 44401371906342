module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Autoscar: Compra e venda veículos novos, usados e seminovos","short_name":"Autoscar","description":"Carros, motos, caminhões, ônibus, vans e veículos em geral. A Autoscar é o lugar perfeito para comprar ou vender seu veículo.","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"standalone","icons":[{"src":"pwa-icons/icon-512.png","sizes":"512x512","type":"image/png","purpose":"any"},{"src":"pwa-icons/icon-384.png","sizes":"384x384","type":"image/png","purpose":"any"},{"src":"pwa-icons/icon-192.png","sizes":"192x192","type":"image/png","purpose":"any"},{"src":"pwa-icons/icon-128.png","sizes":"128x128","type":"image/png","purpose":"any"},{"src":"pwa-icons/icon-96.png","sizes":"96x96","type":"image/png","purpose":"any"},{"src":"pwa-icons/icon-72.png","sizes":"72x72","type":"image/png","purpose":"any"},{"src":"pwa-icons/icon-48.png","sizes":"48x48","type":"image/png","purpose":"any"},{"src":"pwa-icons/maskable-icon-512.png","sizes":"512x512","type":"image/png","purpose":"maskable"},{"src":"pwa-icons/maskable-icon-384.png","sizes":"384x384","type":"image/png","purpose":"maskable"},{"src":"pwa-icons/maskable-icon-192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"pwa-icons/maskable-icon-128.png","sizes":"128x128","type":"image/png","purpose":"maskable"},{"src":"pwa-icons/maskable-icon-96.png","sizes":"96x96","type":"image/png","purpose":"maskable"},{"src":"pwa-icons/maskable-icon-72.png","sizes":"72x72","type":"image/png","purpose":"maskable"},{"src":"pwa-icons/maskable-icon-48.png","sizes":"48x48","type":"image/png","purpose":"maskable"}],"include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5T96JD3","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-2701038-1","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["AW-10862234298"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
