import axios from 'axios';
import { navigate } from 'gatsby';

export const axiosInstance = axios.create({
  baseURL: process.env.BASE_API_DEV,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  config => {
    /* config.headers['Cache-Control'] = 'no-cache'; TODO: Server precisa habilitar esse header para corrigir bug do IE. */
    return config;
  },
  error => error,
);

export const axiosInstanceWithAuth = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: process.env.BASE_API_DEV,
});

export const axiosInstancePlate = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: process.env.BASE_API_DEV_PLATE,
});

axiosInstanceWithAuth.interceptors.request.use(
  config => {
    config.headers.Authorization = `Bearer ${window.localStorage.getItem('Authorization')}`;
    /* config.headers['Cache-Control'] = 'no-cache'; TODO: Server precisa habilitar esse header para corrigir bug do IE. */
    return config;
  },
  error => {
    return error;
  },
);

axiosInstanceWithAuth.interceptors.response.use(
  config => {
    return config;
  },
  error => {
    if (error.response && error.response.status === 401) {
      navigate('/login');
    }
    return Promise.reject(error.response);
  },
);

axiosInstance.interceptors.response.use(
  config => {
    if (config.data.token && typeof window !== 'undefined') {
      window.localStorage.setItem('Authorization', config.data.token);
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export const axiosInstancePagSeguro = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Authorization: process.env.TOKEN_JWT,
  },
  baseURL: process.env.BASE_API_DEV,
});

/** FIXME - quando for mergeado o serviço de escolha de plano remover essa instancia */
export const axiosInstancePlan = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Authorization: process.env.TOKEN_JWT,
  },
  baseURL: 'https://qpr5klijgc.execute-api.us-east-1.amazonaws.com/dev',
});
