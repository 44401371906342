import { axiosInstance } from '../../../../../services/axios-instance';

export const User = {
  postUserAutos: (params: any) =>
    axiosInstance.post(
      '/auth/login',
      { email: params.email, password: params.password },
      { headers: { 'Content-Type': 'application/json' } },
    ),
};
