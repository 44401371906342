import { locationStore } from '../components/Location/store/location';
import { advertisementSearchFilterStore } from '../components/RefineSearch/store';
import user from '../modules/Login/components/SignIn/store/user';
import { selectedPlan } from '../modules/SubscriptionPlans/store/selectedPlan';
import { selectedStore } from '../modules/SubscriptionPlans/store/selectedStore';
import { registerStore } from '../modules/VehicleRegistration/store/registration';

export const stores = {
  user,
  registration: registerStore,
  locale: locationStore,
  advertisementSearchFilter: advertisementSearchFilterStore,
  selectedStore,
  selectedPlan,
};
