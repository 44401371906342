import { axiosInstanceWithAuth } from '../../../services/axios-instance';

interface Payload {
  optionals: string[];
  mileage: number;
  plate: string;
  color: string;
  city: string;
  state: string;
  photos: string[];
  modelId: number;
  price: number;
  description: string;
  ports?: string | number;
  matchType?: string;
  length?: number;
  capacityOfPerson?: number;
}

export const Registration = {
  newAd: (payload: Payload, params: { userId: number; type: number }) => {
    const redirect = !params.userId ? '/advertisement/' : '/advertisement';
    return axiosInstanceWithAuth.post(
      redirect,
      { ...payload },
      {
        headers: { 'Content-Type': 'application/json' },
        params,
      },
    );
  },
  updateAd: (payload: Payload, urlId: string) =>
    axiosInstanceWithAuth.put(
      `/advertisement/${urlId}`,
      { ...payload },
      { headers: { 'Content-Type': 'application/json' } },
    ),
};
