import { axiosInstance } from '../../../services/axios-instance';

export const AdvertisementSearchFilterService = {
  getAdvertisementFilter: async (filter: any) => {
    try {
      const res = await axiosInstance.get('/advertisement', { params: { filter } });
      return res;
    } catch (err) {
      return console.error(err.response);
    }
  },
};

export const search = {
  autocompleteList: (value: string, type: number) =>
    axiosInstance.get('/advertisement/options/autocomplete', {
      params: { search: value, type },
    }),
};
