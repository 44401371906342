import { action, observable } from 'mobx';
import { create, persist } from 'mobx-persist';
import { User } from '../services/User';

export interface UserData {
  email: string;
  password?: string;
  name: string;
  contacts: any;
  token: string;
  fantasyName?: string;
  urlFrontagePhoto: string;
  urlCompanyLogo: string;
  status: string;
  additionalAddressData?: string;
  address?: string;
  id: number;
  addressDistrict?: string;
  addressNumber?: number;
  admin?: boolean;
  type?: number;
  cep?: string;
  city?: string;
  state?: string;
  cpfCnpj?: string;
  whatsapp?: string;
  phone?: string;
}
export interface TypeUserStore {
  profile: UserData;
  updateUserStatus: (status: string) => void;
}
class UserStore implements TypeUserStore {
  @observable
  @persist('object')
  profile = {
    admin: false,
    type: 0,
    email: '',
    name: '',
    token: '',
    contacts: [],
    fantasyName: '',
    urlFrontagePhoto: '',
    urlCompanyLogo: '',
    state: '',
    status: 'unrecognized',
    id: 0,
    profile: {},
  };

  @action
  postAuthUser = async (user: UserData) => {
    try {
      const response = await User.postUserAutos(user);
      this.profile = response.data;
      this.profile.status = 'logged in';
      return response;
    } catch (err: any) {
      return err.response;
    }
  };

  @action
  updateUserData = (user: any) => {
    const saveToken = this.profile.token;
    const isAdmin = this.profile.admin;
    this.profile = user;
    this.profile.token = saveToken;
    this.profile.admin = isAdmin;
  };

  @action
  cleanUser = () => {
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem('Authorization');
    }
    this.profile = {
      email: '',
      name: '',
      token: '',
      fantasyName: '',
      urlFrontagePhoto: '',
      urlCompanyLogo: '',
      state: '',
      contacts: [],
      id: 0,
      admin: false,
      type: 0,
      status: 'logged out',
      profile: {},
    };
  };

  @action
  updateUserStatus = (status: string) => {
    this.profile.status = status;
  };
}

const userStore = new UserStore();
// eslint-disable-next-line import/no-default-export
export default userStore;

if (typeof window !== 'undefined') {
  const hydrate = create();
  hydrate('user', userStore);
}
