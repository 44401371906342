import { action, observable } from 'mobx';
import { create, persist } from 'mobx-persist';
import { Registration } from '../services/Registration';

export interface TypeIdStore {
  idAd: number;
}
class RegisterStore implements TypeIdStore {
  @observable
  @persist('object')
  idAd = 0;

  @action
  registerVehicle = async (
    payload: any,
    urlId: string,
    params: { userId: number; type: number },
  ) => {
    const response = urlId
      ? await Registration.updateAd(payload, urlId)
      : await Registration.newAd(payload, params);
    this.idAd = response.data;
    return response;
  };
}

export const registerStore = new RegisterStore();

if (typeof window !== 'undefined') {
  const hydrate = create();
  hydrate('idAd', registerStore);
}
