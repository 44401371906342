import { action, observable } from 'mobx';
import { create, persist } from 'mobx-persist';

interface IAdvertsementsCollectionModel {
  brandName: string;
  fabricationYear: number;
  id: number;
  modelYear: number;
  motor: string | null;
  name: string;
  price: number;
  status: string;
  type: string;
  version: string;
}

interface IAdvertsementsCollection {
  city: string;
  color: string;
  description: string;
  id: number;
  mileage: number | null;
  model: IAdvertsementsCollectionModel;
  optionals: string[];
  photoURL: string[];
  plate: string;
  price: number;
  state: string;
}

export interface StoreData {
  advertisementsCount: string;
  advertsements: IAdvertsementsCollection[];
  aliasName: string;
  city: string;
  companyName: string;
  cpfCnpj: string;
  createDate: string;
  email: string;
  fantasyName: string;
  id: number;
  name: string;
  phone: string;
  state: string;
  status: string;
  urlCompanyLogo: string;
  urlFrontagePhoto: string;
  whatsapp: string;
}
export interface StoreFromService extends StoreData {
  type: string;
}
export interface StoreMobx extends StoreData {
  type: number;
}
export interface TypeClientStore {
  clientStore: StoreMobx;
  setClientStore: (store: StoreFromService) => void;
  getClientStore: () => StoreMobx;
  cleanClientStore: () => void;
}

class SelectedStore implements TypeClientStore {
  arAdvertsements: IAdvertsementsCollection[] = [];

  @observable
  @persist('object')
  clientStore = {
    advertisementsCount: '',
    advertsements: this.arAdvertsements,
    aliasName: '',
    city: '',
    companyName: '',
    cpfCnpj: '',
    createDate: '',
    email: '',
    fantasyName: '',
    id: 0,
    name: '',
    phone: '',
    state: '',
    status: '',
    type: 0,
    urlCompanyLogo: '',
    urlFrontagePhoto: '',
    whatsapp: '',
  };

  @action
  setClientStore = (store: StoreFromService) => {
    this.clientStore = {
      ...store,
      type: parseInt(store.type, 10),
      aliasName: store.fantasyName || store.companyName || store.name,
    };
  };

  @action
  getClientStore = () => {
    return this.clientStore;
  };

  @action
  cleanClientStore = () => {
    this.clientStore = {
      advertisementsCount: '',
      advertsements: this.arAdvertsements,
      aliasName: '',
      city: '',
      companyName: '',
      cpfCnpj: '',
      createDate: '',
      email: '',
      fantasyName: '',
      id: 0,
      name: '',
      phone: '',
      state: '',
      status: '',
      type: 0,
      urlCompanyLogo: '',
      urlFrontagePhoto: '',
      whatsapp: '',
    };
  };
}

export const selectedStore = new SelectedStore();

if (typeof window !== 'undefined') {
  const hydrate = create();
  hydrate('selectedStore', selectedStore).catch(e => console.error(e.error));
}
