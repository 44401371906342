import { action, observable } from 'mobx';
import { create, persist } from 'mobx-persist';
import { Plan } from '../components/PlanCards/types/plancard';

export interface TypeStorePlan {
  plan: Plan;
  getSelectedPlan: () => Plan;
  setSelectedPlan: (plan: Plan) => void;
}

class SelectedPlan implements TypeStorePlan {
  @observable
  @persist('object')
  plan: Plan = {
    id: 0,
    isFeatured: false,
    features: [{ description: '', availability: false }],
    options: [{ value: '', label: '' }],
    price: 0,
    subtitle: '',
    title: '',
    reference: '',
  };

  @action
  setSelectedPlan = (plan: Plan) => {
    this.plan = plan;
  };

  @action
  getSelectedPlan = () => {
    return this.plan;
  };
}

export const selectedPlan = new SelectedPlan();

if (typeof window !== 'undefined') {
  const hydrate = create();
  hydrate('selectedPlan', selectedPlan).catch(e => console.error(e.error));
}
