import { action, observable } from 'mobx';
import { create, persist } from 'mobx-persist';
import { AdvertisementSearchFilterService } from '../services/index';

interface Props {
  advertisementSearchFilter: any;
}

class AdvertisementSearchFilter implements Props {
  @observable
  @persist('object')
  advertisementSearchFilter = [];

  @action
  getAdvertisementSearchFilter = async (values: {
    initialYear: number;
    finalYear: number;
    price: any;
  }) => {
    const { initialYear, finalYear, price } = values;
    const queryParams: { model_year: object; price?: object } = {
      model_year: {
        operator: 'between',
        initialYear,
        finalYear,
      },
    };

    if (price.initialPrice) {
      queryParams.price = {
        operator: 'between',
        ...price,
      };
    }

    const result = await AdvertisementSearchFilterService.getAdvertisementFilter(queryParams)
      .then((res: any) => {
        this.advertisementSearchFilter = res.data;
        return res;
      })
      .catch(error => error.response);

    return result;
  };
}

export const advertisementSearchFilterStore = new AdvertisementSearchFilter();

if (typeof window !== 'undefined') {
  const hydrate = create();
  hydrate('advertisementSearchFilter', advertisementSearchFilterStore);
}
